<template>
  <div class="m-countdown">
    <!-- 点击前 -->
    <button v-if="click" :class="btnClass" @click="startFun">
      {{ showT }}
    </button>
    <!-- 点击后 -->
    <div :class="textClass" v-else>
      {{ showS }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, defineExpose } from 'vue'
const props = defineProps({
  // 按钮样式
  btnClass: {
    type: String,
    default: ''
  },
  // 文字样式
  textClass: {
    type: String,
    default: ''
  },
  // 初始文字
  text: {
    type: String,
    default: '获取验证码'
  },
  // 结束文字
  endText: {
    type: String,
    default: '重新获取'
  },
  // 计时秒数
  second: {
    type: [String, Number],
    default: 60
  },
  // 模式 -- ++
  mode: {
    type: String,
    default: '-'
  },
  // 计时间隔
  interval: {
    type: [String, Number],
    default: 1000
  }
})
const emits = defineEmits(['start', 'finish'])

// 重新赋值方便重新调用
const click = ref(true)
const showT = ref(props.text)
const showS = ref(props.second)
var time

// 计时--
const countDwon = (s) => {
  s--
  return s
}
const addtime = (s) => {
  s++
  return s
}
// 开始
const start = async () => {
  emits('start', true)
}
// 结束
const finish = (s) => {
  var data = [false, s]
  emits('finish', data)
}

const reset = () => {
  clearInterval(time)
  if (props.endText !== '') {
    showT.value = props.endText
  } else {
    showT.value = props.text
  }
  finish(showS.value)
  showS.value = props.second
  click.value = true
}

// 执行
const startFun = async () => {
  if (!click.value) {
    emits('start', false)
  }
  click.value = false
  await start()
  switch (props.mode) {
    case '+':
      showS.value = 0
      time = setInterval(() => {
        showS.value = addtime(showS.value)
        if (parseInt(showS.value) === parseInt(props.second)) {
          reset()
        }
      }, props.interval)
      break
    default:
      time = setInterval(() => {
        showS.value = countDwon(showS.value)
        if (!showS.value) {
          reset()
        }
      }, props.interval)
      break
  }
}
defineExpose({
  reset
})
</script>
<style lang="scss" scoped>
.m-countdown {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  button {
    width: 100%;
    border-radius: 4px;
    padding: 7px 20px;
    background: rgb(41, 62, 115);
    font-weight: bold;
    color: #f3f5f7;
  }
}
</style>
